import { IconButton } from '@/Components'
import { FynbosLogo } from '@/Components/Logos'
import { Link, usePage } from '@inertiajs/react'
import * as Dialog from '@radix-ui/react-dialog'
import { AnimatePresence, motion } from 'framer-motion'
import { FC, ReactNode, useEffect, useState } from 'react'

type NavDrawerProps = {
  children?: ReactNode
}

export const NavDrawer: FC<NavDrawerProps> = ({ children }) => {
  const [open, setOpen] = useState(false)
  const { url } = usePage()

  useEffect(() => {
    setOpen(false)
  }, [setOpen, url])

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild>
        <IconButton>menu</IconButton>
      </Dialog.Trigger>
      <AnimatePresence>
        {open ? (
          <Dialog.Portal forceMount>
            <Dialog.Overlay className='fixed inset-0 z-40 bg-scrim/75 backdrop-blur-sm data-[state=closed]:animate-overlayHide data-[state=open]:animate-overlayShow' />

            <Dialog.Content asChild>
              <motion.div
                initial={{ x: '-100%' }}
                animate={{ x: 0 }}
                exit={{ x: '-100%' }}
                transition={{ ease: 'easeInOut', duration: 0.3 }}
                className='fixed inset-y-0 left-0 z-50 flex h-dvh min-w-max flex-col bg-white focus:outline-none'
              >
                <div className='flex gap-4 p-4'>
                  <Dialog.Trigger asChild>
                    <IconButton>menu_open</IconButton>
                  </Dialog.Trigger>
                  <Link href='/' className=''>
                    <FynbosLogo />
                  </Link>
                </div>
                <div className='flex w-[18.875rem] grow flex-col gap-1 p-4'>
                  {children}
                </div>
              </motion.div>
            </Dialog.Content>
          </Dialog.Portal>
        ) : null}
      </AnimatePresence>
    </Dialog.Root>
  )
}

NavDrawer.displayName = 'ListItem'
